import React, {useState} from 'react'
import { Link } from 'gatsby'
import { RiMoonLine, RiSunLine } from 'react-icons/ri'
import Footer from './Footer'
import './Layout.css'

const NavLink = ({to, children}) => (
  <Link className="workshop-header-link"
    activeClassName="active"
    to={to}>{children}</Link>
)

const ThemeButton = () => {
  let darkMode = false;
  if (typeof window !== 'undefined') {
    darkMode = window.matchMedia('(prefers-color-scheme: dark)');
  }
  const [isDark, setIsDark] = useState(darkMode);
  
  function toggleDarkMode() {
    document.body.className = isDark ? 'light' : 'dark';
    setIsDark(!isDark);
  }
  
  return (
    <button className="theme-toggle-button"
      onClick={toggleDarkMode}>
      {isDark ? <RiSunLine /> : <RiMoonLine /> }
    </button>
  )
}

const Header = ({ isBrowser }) => {
  return (
    <header className="workshop-header">
      <Link className="workshop-header-logo" to="/">Deep Dive on Design Tokens</Link>
      
      <nav className="workshop-header-nav">
        <NavLink to="/1-introduction">1. Introduction</NavLink>
        <NavLink to="/2-structure">2. Structure</NavLink>
        <NavLink to="/3-integration">3. Integration</NavLink>
        <NavLink to="/4-advanced">4. Advanced</NavLink>
        <NavLink to="/resources">Resources</NavLink>
      </nav>
      
      <ThemeButton />
    </header>
  )
}

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="app">
        <Header />
        <div className="content">
          {children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Layout
