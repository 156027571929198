import React from 'react';
import './Define.css';

const definitions = {
  'format': `A format is a blah`
}

const Define = ({ children, text }) => {
  const definition = definitions[text];
  return (
      <span className="definition">
        {children}
        {definition ? <span className="definition-tooltip">{definition}</span> : null}
      </span>
    )
}

export default Define