import React from 'react';
import './Article.css';
import { DiGithubBadge } from 'react-icons/di'
import { RiFileList2Line, RiGithubLine, RiMovieLine, RiPlugLine } from 'react-icons/ri'

const typeMap = {
  article: <RiFileList2Line />,
  github: <RiGithubLine />,
  presentation: <RiMovieLine />,
  plugin: <RiPlugLine />
}

const Article = ({ url, author, title, description, type='article' }) => (
  <article className="article">
    <h3 className="article-title">
      <a href={url}>{typeMap[type]} {title}</a>
    </h3>
    <h6 className="article-author">{author}</h6>
    <p className="article-description">{description}</p>
  </article>
)

export default Article