import React from 'react';
import './Callout.css';

export default ({ children, type="info", title }) => {
  return (
    <section className={`callout callout-${type}`}>
      {title ? <h5>{title}</h5> : null}
      {children}
    </section>
  )
}
